@import "_variables";

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url('#{$theme-path}/fonts/google/Open-Sans-Light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('#{$theme-path}/fonts/google/Open-Sans.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('#{$theme-path}/fonts/google/Open-Sans-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url('#{$theme-path}/fonts/google/Open-Sans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url('#{$theme-path}/fonts/google/Open-Sans-Light-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url('#{$theme-path}/fonts/google/Open-Sans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url('#{$theme-path}/fonts/google/Open-Sans-Semibold-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url('#{$theme-path}/fonts/google/Open-Sans-Bold-Italic.woff') format('woff');
}

